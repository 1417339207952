/* Colors
 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

$yellow: #ffc107;
$black: #252525;
$red: #db695d;
$white: #fff;

/* Reset
 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

/* Main
 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

html {
	font-size: 100%;
}

body {
	background: url('/img/background.jpg') no-repeat fixed;
	font-family: 'Roboto Slab', arial, sans-serif;
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
	justify-content: center;
	background-size: cover;
	flex-direction: column;
	align-items: center;
	color: $white;
	display: flex;
	height: 100vh;
}

a {
	color: $white;
}

h1 {
	font-size: 1.8rem;
	font-weight: 400;
}

p {
	font-size: 1.3rem;
	margin: 20px;
}

.logo-wrap {
	margin: 0 auto 3rem;
	position: relative;

	&:hover {
		.mascot {
			transition-delay: 1s;
			left: -55px;
			top: 20px;
		}

		.mascot-bubble {
			transition-delay: 2s;
			opacity: 1;
		}
	}
}

.logo {
	position: relative;
	display: block;
	height: 15rem;
	width: 15rem;
	z-index: 2;
}

/* Mascot
 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.mascot-bubble {
	background-color: $yellow;
	border: 1px solid $black;
	transition: all .1s ease;
	border-radius: 5px;
	position: absolute;
	text-shadow: none;
	padding: 5px 10px;
	color: $black;
	opacity: 0;
	z-index: 5;
	left: 5px;
	top: 10px;

	&::before {
		border-color: transparent $black;
		border-width: 12px 12px 12px 0;
		transform: translateY(-50%);
		border-style: solid;
		position: absolute;
		left: -12px;
		content: '';
		z-index: 5;
		top: 50%;
	}

	&::after {
		border-color: transparent $yellow;
		border-width: 10px 10px 10px 0;
		transform: translateY(-50%);
		border-style: solid;
		position: absolute;
		left: -10px;
		content: '';
		z-index: 6;
		top: 50%;
	}
}

.mascot {
  transform: scale(0.3, 0.3) rotate(-40deg);
	transition: all .15s ease;
	position: absolute;
	z-index: 1;
	top: 40px;
	left: 0;
}

.mascot__body {
	border-radius: 70px 70px 0 0;
	background-color: $yellow;
	position: absolute;
	height: 300px;
	width: 160px;
}

.mascot__left-eye {
	background-color: $white;
	border: 2px solid $black;
	margin: 20px 0 0 40px;
	border-radius: 50px;
	position: absolute;
	height: 43px;
	width: 37px;

	&::after {
		background-color: $black;
		margin: 16px 0 0 11px;
		border-radius: 50px;
		position: absolute;
		height: 10px;
		width: 10px;
		content: '';
	}
}

.mascot__right-eye{
	background-color: $white;
	border: 2px solid $black;
	margin: 25px 0 0 82px;
	border-radius: 50px;
	position: absolute;
	height: 38px;
	width: 31px;

	&::after{
		background-color: $black;
		margin: 13px 0 0 9px;
		border-radius: 50px;
		position: absolute;
		height: 10px;
		width: 10px;
		content: '';
	}
}

.mascot__smile {
	border-radius: 10px 10px 70px 70px;
	background-color: $black;
	transform: rotate(20deg);
	margin: 80px 0 0 30px;
	position: absolute;
	height: 35px;
	width: 70px;
}

.mascot__tongue{
	border-radius: 30px 50px 70px 50px;
	transform: rotate(21deg);
	background-color: $red;
	margin: 99px 0 0 46px;
	position: absolute;
	height: 15px;
	width: 30px;
	z-index: 2;
}